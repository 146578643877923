import React from 'react';
import Layout from '../../../components/site/Layout/Base/Layout';

const News = () => {
  return (
    <Layout>
      <div className="container mx-auto h-100 flex justify-center items-center">
        <p className="text-4xl">Coming Soon ....</p>
      </div>
    </Layout>
  );
};

export default News;
