// UserCarPostingForm.js
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import Layout from '../../../components/site/Layout/Base/Layout';
import FullWidthSection from '../../../components/site/FullWidthSection/FullWidthSection';
import UsedCarPostingForm from './UsedCarPostingForm';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import {
	createUsedCar,
	fetchUsedCarSingle,
	updateUsedCar,
	fetchColorList,
	fetchCarModelList,
	fetchCarSubModelPublicList,
	fetchBrandPublicList,
	getMerchantSellers,
	fetchSellerList,
} from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';
import { deleteImageCarSuccess } from '../../../store/actions/UsedCarAction';

const UserCarPostingForm = (props) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const {
		loading,
		error,
		success,
		createUsedCar,
		fetchUsedCarSingle,
		singleUsedCar,
		updateUsedCar,
		fetchColorList,
		colorList,
		fetchCarSubModelPublicList,
		carSubModelPublicList,
		carModelList,
		fetchCarModelList,
		fetchBrandPublicList,
		brandPublicList,
		getMerchantSellers,
		userData,
		sellers,
		fetchSellerList,
		sellerList,
		deleteImageCarSuccess
	} = props;

	const [sellersData, setSellersData] = useState([]);
	const [selectedSellers, setSelectedSellers] = useState([]);

	

	const fetchData = async () => {
		await Promise.all([
			fetchColorList('all', true, true),
			fetchBrandPublicList('all'),
			fetchCarModelList('all', true, true),
			fetchCarSubModelPublicList(),
			getMerchantSellers(userData?.id),
			fetchSellerList('all', userData.id),
		]);
	};

	useEffect(() => {
		deleteImageCarSuccess({})
		fetchData();
		setSelectedSellers([]);
	}, []);

	useEffect(() => {
		if (sellerList?.length > 0) {
			const activeSellers = sellerList.filter((item) => item?.user?.status === 1);
			const formatedSeller = activeSellers?.map((item) => ({
				value: item?.id,
				label: item?.user?.username,
			}));

			setSellersData(formatedSeller);
		}
	}, [sellerList?.length]);

	useEffect(() => {
		if (singleUsedCar?.id) {
			const formatedSeller = singleUsedCar?.used_cars_sellers?.map((item) => ({
				value: item?.seller_id,
				label: item?.seller?.user?.username,
			}));
			setSelectedSellers(formatedSeller);
		}
	}, [id, singleUsedCar?.used_cars_sellers, singleUsedCar?.id]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					navigate('/user-dashboard');
				},
				onClose: () => { },
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	}, [success, error]);

	useEffect(() => {
		if (id) {
			fetchUsedCarSingle(id, true);
		}
	}, [id]);

	const onSubmit = (values) => {
		const sellers = selectedSellers?.map((item) => item?.value);
		const updatedValue = { ...values, sellers };

		if (id) {
			const stringiFyImg = JSON.stringify(updatedValue?.images)
			const newEditValue = {...updatedValue, images: stringiFyImg}
			console.log('edit value ===>>', newEditValue)
			updateUsedCar(id, newEditValue, true);
		} else {
			console.log('updatedValue ===>', updatedValue)
			createUsedCar(updatedValue);
		}
	};

	const renderContent = () => {
		if (loading) {
			return <Loader />
		}

		if (id  && carModelList.length > 0 && colorList.length > 0 && singleUsedCar) {
			return (
				<UsedCarPostingForm
					selectedSellers={selectedSellers}
					setSelectedSellers={setSelectedSellers}
					sellersData={sellersData}
					onSubmit={onSubmit}
					initialValues={singleUsedCar}
					colorList={colorList}
					carModelList={carModelList ?? []}
					carSubModelList={carSubModelPublicList ?? []}
					brandList={brandPublicList}
				/>
			);
		}

		if (!loading && carModelList.length > 0 && colorList.length > 0) {
			return (
				<UsedCarPostingForm
					selectedSellers={selectedSellers}
					setSelectedSellers={setSelectedSellers}
					sellersData={sellersData}
					onSubmit={onSubmit}
					colorList={colorList}
					carModelList={carModelList ?? []}
					carSubModelList={carSubModelPublicList ?? []}
					brandList={brandPublicList}
				/>
			);
		}
	};

	

	return (
		<Layout>
			<FullWidthSection title="Post Car Advertisement" bgColor="#cccccc" />
			<div className="container mx-auto">
				{renderContent()}
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.usedCar.loading,
		error: state.usedCar.error,
		success: state.usedCar.success,
		usedCarList: state.usedCar.usedCarList,
		singleUsedCar: state.usedCar.singleUsedCar,
		colorList: state.color.colorList,
		carModelList: state.carModel.carModelList,
		carSubModelPublicList: state.carSubModel.carSubModelPublicList,
		brandPublicList: state.brand.brandPublicList,
		userData: state.auth.userData,
		sellers: state.merchant.sellers,
		sellerList: state.seller.sellerList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createUsedCar: (data) => dispatch(createUsedCar(data)),
		fetchUsedCarSingle: (id, isPublic) =>
			dispatch(fetchUsedCarSingle(id, isPublic)),
		updateUsedCar: (id, data, isPublic) =>
			dispatch(updateUsedCar(id, data, isPublic)),
		fetchColorList: (page, isProcess, isPublic) =>
			dispatch(fetchColorList(page, isProcess, isPublic)),
		fetchBrandPublicList: (page) => dispatch(fetchBrandPublicList(page)),
		fetchCarModelList: (page, isProcess, isPublic) =>
			dispatch(fetchCarModelList(page, isProcess, isPublic)),
		fetchCarSubModelPublicList: () => dispatch(fetchCarSubModelPublicList()),
		getMerchantSellers: (id) => dispatch(getMerchantSellers(id)),
		fetchSellerList: (page, id) => dispatch(fetchSellerList(page, id)),
		deleteImageCarSuccess: (data) => dispatch(deleteImageCarSuccess(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCarPostingForm);
