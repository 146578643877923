export function deRegCalculator(arf, registrationDate, coe, cor_selection = 0) {
    let deRegValue = 0;

    // if new coe (0) Dereg value will be the entire amount of the COE
    if (cor_selection == 0) {
        deRegValue = coe
    }else{

        console.log(cor_selection, "cor_selection alue");
        
        console.log('Registration Date',registrationDate);
        
        console.log("ARF", arf);
        let totalMonths = getTotalMonth(registrationDate)
        console.log('Total Months:', totalMonths);

        if (!isNaN(totalMonths) && totalMonths > 0) {

            // Determine the rebate percentage and cap based on the total months
            let rebatePercentage = 0;
            let rebateCap = 0;

            if (totalMonths <= 60) {
                rebatePercentage = 75;
                rebateCap = 60000;
            } else if (totalMonths <= 72) {
                rebatePercentage = 70;
                rebateCap = 60000;
            } else if (totalMonths <= 84) {
                rebatePercentage = 65;
                rebateCap = 60000;
            } else if (totalMonths <= 96) {
                rebatePercentage = 60;
                rebateCap = 60000;
            } else if (totalMonths <= 108) {
                rebatePercentage = 55;
                rebateCap = 60000;
            } else if (totalMonths <= 120) {
                rebatePercentage = 50;
                rebateCap = 60000;
            } else {
                rebatePercentage = 0;
                rebateCap = 0;
            }

            console.log('Rebate Percentage:', rebatePercentage);
            console.log('Rebate Cap:', rebateCap);

            // Calculate the rebate amount
            let parfAmount = (arf * (rebatePercentage / 100));
            if (parfAmount > rebateCap) {
                parfAmount = rebateCap;
            }

            console.log('PARF Amount:', parfAmount);

            let coeRemainingMonths = 120 - totalMonths
            let coePerMonth = parseFloat(coe / 120).toFixed(2)


            console.log('coe', coe);
            console.log('remaining month', coeRemainingMonths);
            console.log('per month', coePerMonth);

            let coeRebate = coePerMonth * coeRemainingMonths

            console.log('coe rebate', coeRebate);

            deRegValue = coeRebate + parfAmount
        }
    }


    return parseFloat(deRegValue).toFixed(2)

}

const getTotalMonth = (registrationDate) => {
    console.log(registrationDate);
    
    if (registrationDate != null) {
        let startDate = new Date(registrationDate);
        let endDate = new Date();

        let totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();

        if (endDate.getDate() >= startDate.getDate()) {
            totalMonths += 1;
        }

        if (totalMonths == 0) {
            totalMonths = 1;
        }

        return totalMonths;
    }else{
        return 0;
    }
}