import React from 'react';

const LatestCars = ({ img, title, excerpt, category, date, link }) => {
	const imageSrc = `${process.env.REACT_APP_BASE_URL}${img}`;

	return (
		<div className="border-[#E8E7E6] border w-full shadow-lg p-2">
			 <a href={`news/${link}`}>
				<img src={imageSrc} alt={title} className="w-full" />
			</a>
			<div className="w-fit p-2">
				<a href={`news/${link}`} className="tracking-widest font-bold">
					{title}
				</a>
				<p className="text-[#666]">
					{excerpt}
				</p>
				<p className="my-5">
					<span>{category}</span> | <span>{date}</span>
				</p>
			</div>
		</div>
	);
};

export default LatestCars;
