import React, { useState } from 'react';

import Select from 'react-select';
import { useFormik } from 'formik';
import CarFilterSchema from '../../../schemas/CarSearchFilterSchema';
import {
  yearOptions,
  carPriceRanges,
  depreciationValue,
} from './../../../constants/index';

const HeroBox = ({
  img,
  vehicleData,
  onSearch,
  brandList,
  carModelList,
  title,
}) => {
  const [modelData, setModelData] = useState([]);
  let initVal = {
    brand_id: '',
    model_id: '',
    price_range: '',
    year_range: '',
    vehicle_id: '',
    depreciation_range: '',
  };
  const formik = useFormik({
    initialValues: initVal,
    validationSchema: CarFilterSchema,
    onSubmit: onSearch,
  });

  const handleBrandChange = (selectedOption) => {
    const filteredModels = carModelList.filter(
      (model) => model.brand === selectedOption.value
    );
    setModelData(filteredModels);
    formik.setFieldValue('brand_id', selectedOption.value);
  };
  // console.log('formik.values', formik.values['depreciation_range']);
  return (
    <div className="relative mb-[455px] lg:mb-0 container mx-auto">
      <img src={img} alt="" className="w-screen  lg:h-[512px] md:h-[300px] " />
      <div
        className={`opacity-95 bg-white shadow-lg md:bg-gray-3 md:px-12 rounded px-4 md:py-1 absolute lg:-top-44  lg:mt-52 lg:right-7 mx-auto  my-2 lg:left-auto w-full lg:w-fit md:w-full h-fit lg:h-[445px] `}
      >
        <div className="lg:w-[540px]  w-full container p-1  lg:h-[389px] lg:mt-2 bg-gray-100 mx-auto ">
          <div className="text-blue-800 my-4">
            <p className="font-bold my-2 text-[#0534FF]">{title}</p>
            <p className="font-semibold text-2xl text-[#0534FF]">
              Find your dream car
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="grid items-center grid-cols-2 gap-3">
              <div>
                <div>
                  <label htmlFor="" className="text-sm sm:text-lg">
                    Car Make or Model
                  </label>
                  <Select
                    id={'brand_id'}
                    name={'brand_id'}
                    value={
                      brandList &&
                      brandList?.filter(
                        (option) => option.value == formik?.values['brand_id']
                      )
                    }
                    onChange={(selectedOption) =>
                      handleBrandChange(selectedOption)
                    }
                    onBlur={formik.handleBlur}
                    options={brandList}
                    placeholder={'Select'}
                    className="w-full my-2 shadow-sm sm:text-sm"
                  />
                  {formik.touched['brand_id'] && formik.errors['brand_id'] && (
                    <p className="text-sm text-[#F87171] mt-1">
                      {formik.errors['brand_id']}
                    </p>
                  )}
                </div>

                {title === 'NEW CARS' ? (
                  <div>
                    <label htmlFor="" className="text-sm sm:text-lg">
                      Depreciation
                    </label>
                    <Select
                      id={'depreciation_range'}
                      name={'depreciation_range'}
                      value={depreciationValue.filter(
                        (option) =>
                          option.value === formik.values['depreciation_range']
                      )}
                      onChange={(e) =>
                        formik.setFieldValue('depreciation_range', e.value)
                      }
                      options={depreciationValue}
                      placeholder={'Select '}
                      className="w-full my-2 shadow-sm sm:text-sm"
                    />
                    {formik.touched['depreciation_range'] &&
                      formik.errors['depreciation_range'] && (
                        <p className="text-sm text-[#F87171] mt-1">
                          {formik.errors['depreciation_range']}
                        </p>
                      )}
                  </div>
                ) : (
                  <div>
                    <label htmlFor="" className="text-sm sm:text-lg">
                      Model
                    </label>
                    <Select
                      id={'model_id'}
                      name={'model_id'}
                      value={
                        modelData &&
                        modelData.filter(
                          (option) => option.value == formik.values['model_id']
                        )
                      }
                      onChange={(e) =>
                        formik.setFieldValue('model_id', e.value)
                      }
                      onBlur={formik.handleBlur}
                      options={modelData}
                      placeholder={'Select '}
                      className="w-full my-2 shadow-sm sm:text-sm"
                    />
                    {formik.touched['model_id'] &&
                      formik.errors['model_id'] && (
                        <p className="text-sm text-[#F87171] mt-1">
                          {formik.errors['model_id']}
                        </p>
                      )}
                  </div>
                )}
              </div>
              <div>
                <div>
                  <label htmlFor="" className="text-sm sm:text-lg">
                    Price Range
                  </label>
                  <Select
                    id={'price_range'}
                    name={'price_range'}
                    value={carPriceRanges.filter(
                      (option) => option.value == formik.values['price_range']
                    )}
                    onChange={(e) =>
                      formik.setFieldValue('price_range', e.value)
                    }
                    options={carPriceRanges}
                    placeholder={'Select '}
                    className="w-full my-2 shadow-sm sm:text-sm text-wrap	sm:text-wrap	"
                  />
                  {formik.touched['price_range'] &&
                    formik.errors['price_range'] && (
                      <p className="text-sm text-[#F87171] mt-1">
                        {formik.errors['price_range']}
                      </p>
                    )}
                </div>
                <div>
                  <label htmlFor="" className="text-sm sm:text-lg">
                    Vehicle Type
                  </label>
                  <Select
                    id={'vehicle_id'}
                    name={'vehicle_id'}
                    value={
                      vehicleData &&
                      vehicleData?.filter(
                        (option) => option.value == formik.values['vehicle_id']
                      )
                    }
                    onChange={(e) =>
                      formik.setFieldValue('vehicle_id', e.value)
                    }
                    onBlur={formik.handleBlur}
                    options={vehicleData}
                    placeholder={'Select '}
                    className="w-full my-2 shadow-sm sm:text-sm"
                  />
                  {formik.touched['vehicle_id'] &&
                    formik.errors['vehicle_id'] && (
                      <p className="text-sm text-[#F87171] mt-1">
                        {formik.errors['vehicle_id']}
                      </p>
                    )}
                </div>
              </div>
            </div>

            <div className={`${title === 'NEW CARS' && 'hidden'}`}>
              <label htmlFor="" className="text-sm sm:text-lg">
                Year Reg
              </label>
              <Select
                id={'year_range'}
                name={'year_range'}
                value={yearOptions.filter(
                  (option) => option.value === formik.values['year_range']
                )}
                onChange={(e) => formik.setFieldValue('year_range', e.value)}
                options={yearOptions}
                placeholder={'Select '}
                className="w-full my-2 shadow-sm sm:text-sm"
              />
              {formik.touched['year_range'] && formik.errors['year_range'] && (
                <p className="text-sm text-[#F87171] mt-1">
                  {formik.errors['year_range']}
                </p>
              )}
            </div>

            <div
              className={`md:flex items-center justify-start gap-6 my-2.5 ${
                title === 'NEW CARS' && 'my-8'
              }`}
            >
              {/* <Link to={`/searched_used_cars`}> */}
              <button
                type="submit"
                className="bg-[#0534FF] w-full md:w-40 md:px-8 py-4 my-2 font-semibold text-white border rounded-2xl"
              >
                Search
              </button>
              {/* </Link> */}
              <p className="text-[#0534FF] underline cursor-pointer text-center">
                Advanced Search
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HeroBox;
