import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import FormField from '../../../components/common/FormFileds/Admin/FormField';

import { MultiSelect } from '../../../components/common/MultiSelect/MultiSelect';
import { ownerIdTYpeOptions, coeTypeOptions } from '../../../constants';
import UsedCarSchema from '../../../schemas/UsedCarSchema';
import {
	deleteImageUsedCar,
	fetchPublicAddsOnsList,
	fetchPublicAdsTypeList,
} from '../../../store/actions';
import MultipleImgUpload from '../../../components/common/FormFileds/Admin/MultipleImgUpload';
import MultipleImgUpdate from '../../../components/common/FormFileds/Admin/MultipleImgUpdate';

const UsedCarPostingForm = (props) => {
	const {
		initialValues,
		onSubmit,
		colorList,
		carModelList,
		carSubModelList,
		brandList,
		deleteImageUsedCar,
		sellersData,
		setSelectedSellers,
		selectedSellers,
		fetchPublicAdsTypeList,
		publicAdsTypeList,
		fetchPublicAddsOnsList,
		publicAddsOnsList,
		userData,
	} = props;

	const [modelData, setModelData] = useState([]);
	const [subModelData, setSubModelData] = useState([]);
	const [imageSources, setImageSources] = useState([]);

	let [adTypeData, setAdTypeData] = useState([]);
	let serviceData = publicAddsOnsList;
	useEffect(() => {
		if (props?.userData && props?.publicAdsTypeList) {
			if (props?.userData?.type === 'customer') {
				let customerData = props?.publicAdsTypeList?.filter(
					(data) => data?.type === 'individual'
				);
				setAdTypeData(customerData);
			} else if (props?.userData?.type === 'merchant') {
				let merchantData = props?.publicAdsTypeList?.filter(
					(data) => data?.type === 'merchant'
				);
				setAdTypeData(merchantData);
			}
		}
	}, [props.userData, props?.publicAdsTypeList]);

	let initVal = {
		ads_type_id: '',
		ads_on_id: '',
		car_plate_no: '',
		owner_id_type_id: '',
		color_id: '',
		owner_id: '',
		price: '',
		mileage: '',
		prefered_area: '',
		desc: '',
		chasis_number: '',
		allow_email: 0,
		image: '',
		sub_model_id: '',
		model_id: '',
		brand_id: '',
		promotions: '',
		isImageRequired: true,
	};

	const formik = useFormik({
		initialValues: initialValues || initVal,
		validationSchema: UsedCarSchema,
		onSubmit,
	});

	const handleBrandChange = (e) => {
		let filteredModels = carModelList.filter(
			(model) => model.brand == e.target.value
		);
		setModelData(filteredModels);
		setSubModelData([])
		formik.setFieldValue("model_id", "")
		formik.setFieldValue("sub_model_id", "")
	};
	const handleModelChange = (e) => {
		let filteredSubModels = carSubModelList.filter(
			(subModel) => subModel.model == e.target.value
		);
		setSubModelData(filteredSubModels);
		formik.setFieldValue("sub_model_id", "")

	};

	

	useEffect(() => {
		fetchPublicAdsTypeList(1);
		fetchPublicAddsOnsList(1);
		formik.setValues(initialValues || initVal);
		if (initialValues?.images !== null && initialValues?.images?.length > 0) {
			const parstImages = JSON.parse(initialValues?.images)
			setImageSources(parstImages);
		}
	}, [initialValues, fetchPublicAdsTypeList, fetchPublicAddsOnsList]);

	useEffect(() => {
		if (initialValues !== null && initialValues !== undefined) {
		
			let filteredModels = carModelList.filter(
				(model) => model.brand === initialValues.brand_id
			);
			setModelData(filteredModels);
			

			
			let filteredSubModels = carSubModelList.filter(
				(subModel) => subModel.model == initialValues.model_id
			);
			setSubModelData(filteredSubModels);
		
		}
	}, [initialValues]);

	if (initialValues !== undefined && initialValues !== null) {
		if (initialValues.ads_type_id) {
			adTypeData = adTypeData.filter(
				(item) => initialValues.ads_type_id == item.value
			);
		}

		if (initialValues.ads_on_id) {
			serviceData = serviceData?.filter(
				(item) => initialValues.ads_on_id == item.value
			);
		}
	}

	


	return (
		<form onSubmit={formik.handleSubmit}>
			<div className="p-6.5">
				<p className="mb-4">Ad Type</p>
				<div className="flex flex-wrap mb-4">
					<FormField
						type="radiobutton"
						radioData={adTypeData}
						formik={formik}
						name={'ads_type_id'}
					/>
				</div>

				<p className="mb-4">Photography Services</p>
				<div className="flex flex-wrap mb-4">
					<FormField
						type="radiobutton"
						radioData={serviceData}
						formik={formik}
						name={'ads_on_id'}
					/>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Car Plate No'}
							id={'car_plate_no'}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label={'Owner Id Type'}
							id={'owner_id_type_id'}
							options={ownerIdTYpeOptions}
							formik={formik}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Owner Id'}
							id={'owner_id'}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Asking Price (Optional)'}
							id={'price'}
							formik={formik}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label={`${userData?.type === 'customer' ? 'Brand (Optional)' : 'Brand'
								}`}
							id="brand_id"
							options={brandList}
							formik={formik}
							customOnChange={(e) => handleBrandChange(e)}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label={`${userData?.type === 'customer' ? 'Model (Optional)' : 'Model'
								}`}
							id="model_id"
							options={modelData}
							formik={formik}
							customOnChange={(e) => handleModelChange(e)}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label={`${userData?.type === 'customer'
									? 'Sub Model (Optional)'
									: 'Sub Model'
								}`}
							id="sub_model_id"
							options={subModelData}
							formik={formik}
						/>
					</div>
				</div>
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label={'Color'}
							id={'color_id'}
							options={colorList}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Mileage (Optional)'}
							id={'mileage'}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Preferred Viewing Area (Optional)'}
							id={'prefered_area'}
							formik={formik}
						/>
					</div>
				</div>
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-2/2">
						<FormField
							type="textarea"
							label={'Promotions (up to 500 characters)'}
							id={'promotions'}
							formik={formik}
						/>
					</div>
				</div>
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-full">
						<FormField
							type="select"
							label="COE Type"
							id="cor_selection"
							options={coeTypeOptions}
							formik={formik}
						/>
					</div>
				</div>
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div
						className={`w-full  xl:w-1/2 ${userData?.type === 'customer' ? 'hidden' : 'visible'
							}`}
					>
						<MultiSelect
							optionsData={sellersData}
							selectedOptions={selectedSellers}
							setSelectedOptions={setSelectedSellers}
							label="Assign Seller"
							placeholder="Select Seller"
						/>
					</div>
					<div className="w-full  xl:w-1/2">
						<FormField
							type="text"
							label={'Chassis number (Optional)'}
							id={'chasis_number'}
							formik={formik}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-2/2">
						<FormField
							type="textarea"
							label={'Description (up to 500 characters)'}
							id={'desc'}
							formik={formik}
						/>
					</div>
				</div>

				<div className="flex flex-wrap mb-4">
					<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
						<FormField
							type="checkbox"
							label={'Allow enquiry to be send to my email'}
							id={'allow_email'}
							formik={formik}
						/>
					</div>
				</div>
				<div>
					{/* <FileUpload formik={formik} id={'image'} multiple={true} /> */}
					{
						initialValues?.id ? <MultipleImgUpdate imageSources={imageSources} formik={formik} id={'images'} multiple={true} /> : <MultipleImgUpload formik={formik} id={'image'} multiple={true} />
					}
				</div>

				<div className="mt-45 flex justify-end gap-4.5">
					<button
						type="submit"
						className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-70"
					>
						Save
					</button>
				</div>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state.auth.userData,
		publicAdsTypeList: state.adsType.publicAdsTypeList,
		publicAddsOnsList: state.addsOns.publicAddsOnsList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteImageUsedCar: (id) => dispatch(deleteImageUsedCar(id)),
		fetchPublicAdsTypeList: (id) => dispatch(fetchPublicAdsTypeList(id)),
		fetchPublicAddsOnsList: (id) => dispatch(fetchPublicAddsOnsList(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsedCarPostingForm);
