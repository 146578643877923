import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../components/common/FormFileds/Admin/FormField';
import FileUpload from '../../../components/common/FormFileds/Admin/FileUpload';
import { toast } from 'react-toastify';
import UpdateUsedCarSchema from '../../../schemas/UpdateUsedCarSchema';
import {
	ownerIdTYpeOptions,
	usedCarStatusOptions,
	transmissionTypeOptions,
	coeTypeOptions
} from '../../../constants';
import ImageGrid from '../../../components/common/ImageGrid';
import { deleteImageUsedCar } from '../../../store/actions';
import { connect } from 'react-redux';
import MultipleImgUpdate from '../../../components/common/FormFileds/Admin/MultipleImgUpdate';
import { deRegCalculator } from '../../../helper/deREgCalculation';

const UsedCarForm = (props) => {
	const {
		initialValues,
		onSubmit,
		brandList,
		carModelList,
		fuelTypeList,
		vehicleTypeList,
		carCategoryList,
		// onModelChange,
		carSubModelList,
		colorList,
		deleteImageUsedCar
		// deleteimage,
	} = props;
	const [subModelOption, setSubModelOption] = useState([]);
	const [modelList, setModelList] = useState([]);
	const [updatedFlag, setUpdateFlag] = useState(false);
	const [imageSources, setImageSources] = useState([]);

	let initVal = {
		add_type: '',
		service_type: '',
		car_plate_no: '',
		owner_id_type_id: '',
		owner_id: '',
		price: '',
		mileage: '',
		prefered_area: '',
		desc: '',
		chasis_number: '',
		is_enquiry: false,
		fuel_type_id: '',
		remarks: '',
		status: '',
		vehicle_type_id: '',
		color_id: '',
		engine_capacity: '',
		power: '',
		depreciation: '',
		dereg_value: '',
		coe: '',
		coe_expiry: '',
		omv: '',
		arf: '',
		no_of_owner: '',
		registration_date: '',
		original_registration_date: '',
		transmission_type: '',
		category_id: '',
		sub_model_id: '',
		model_id: '',
		images: '',
		engine_number: '',
		ads_expire_at: '',
		is_forFeited: '',
		promotions: '',
		cor_selection: 0,
		deregValue: 0
	};

	const formik = useFormik({
		initialValues: initialValues || initVal,
		validationSchema: UpdateUsedCarSchema,
		onSubmit,
	});

	useEffect(() => {
		formik.setValues(initialValues || initVal);
		if (initialValues !== null && initialValues !== undefined) {
			const parstImages = JSON.parse(initialValues?.images)
			setImageSources(parstImages);

			if (initialValues.brand_id !== null) {
				handleBrandChange(initialValues.brand_id, true);
			}

			if (initialValues.model_id !== null) {
				handleModelChange(initialValues.model_id, true);
			}
		}
	}, [initialValues]);

	useEffect(() => {
		if (updatedFlag) {
			const parstImages = formik?.values?.images

			formik.setValues({ ...initialValues, images: parstImages });
			setUpdateFlag(false);
		}
	}, [updatedFlag]);



	const handleDeRegCalculation = () => {
		console.log(formik.values.arf, 'arf');
		console.log(formik.values.registration_date, 'reg date');
		console.log(formik.values.coe, 'coe');
		
		let deRegVal = deRegCalculator(formik.values.arf, formik.values.registration_date, formik.values.coe, formik.values.cor_selection)
		toast.success('Dereg Value calculated', {
			position: toast.POSITION.TOP_RIGHT,
			onOpen: () => {},
			onClose: () => {},
		});
		formik.setFieldValue('dereg_value', deRegVal)
	};

	const handleBrandChange = (val, fromUseEffect = false) => {
		initialValues.brand_id = val;
		const filteredModels = carModelList.filter((model) => model.brand == val);

		setModelList(filteredModels);
		setSubModelOption([]);

		if (!fromUseEffect) {
			formik.setFieldValue("model_id", null);
			formik.setFieldValue("sub_model_id", null);
		}
	};

	const handleModelChange = (val, fromUseEffect = false) => {
		initialValues.model_id = val;
		const formattedSubModels = [];

		carSubModelList.forEach((subModel) => {
			if (subModel.model_id == val) {
				formattedSubModels.push({
					value: subModel.id,
					label: subModel?.key_info?.name,
					model: subModel.model_id,
				});
			}
		});
		setSubModelOption(formattedSubModels);
		if (!fromUseEffect) {
			formik.setFieldValue("sub_model_id", null)
		}
	};

	const handleSubModelChange = (e) => {
		if (carSubModelList) {
			const result = carSubModelList.find((item) => item.id == e.target.value);
			initialValues.sub_model_id = e.target.value;
			if (result && result.key_info != undefined) {
				initialValues.coe = result.key_info.coe_price;
				initialValues.depreciation = result.key_info.depreciation;
				initialValues.omv = result.key_info.omv;
				initialValues.arf = result.key_info.arf;
			}

			if (result && result.enginer_transmission != undefined) {
				initialValues.transmission_type =
					result.enginer_transmission.transmission_type;
				initialValues.engine_capacity =
					result.enginer_transmission.engine_capacity;
				initialValues.fuel_type_id = result.enginer_transmission.fuel_type_id;
			}

			if (result && result.performance != undefined) {
				initialValues.power = result.performance.power;
			}

			setUpdateFlag(true);
		}
	};

	// console.log('imageSources', imageSources);
	const handleDeleteImg = (urlData) => {
		const imgUrl = { url: urlData }
		deleteImageUsedCar(imgUrl);
		// console.log('image', id);
	};

	return (
		<AdminForm formik={formik} onBtnCancelClick={props.onCancel}>
			<div className="p-6.5">
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Name'}
							id={'name'}
							formik={formik}
							disabled
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Contact No'}
							id={'phone'}
							formik={formik}
							disabled
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Email'}
							id={'email'}
							formik={formik}
							disabled
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Car Plate No'}
							id={'car_plate_no'}
							formik={formik}
							disabled
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label={'Owner Id Type'}
							id={'owner_id_type_id'}
							options={ownerIdTYpeOptions}
							formik={formik}
							disabled
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Owner Id'}
							id={'owner_id'}
							formik={formik}
							disabled
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label={'Color'}
							id={'color_id'}
							options={colorList}
							formik={formik}
						/>
					</div>
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Preferred Viewing Area'}
							id={'prefered_area'}
							formik={formik}
							disabled
						/>
					</div>

					<div className="w-full xl:w-1/2 mt-5">
						<FormField
							type="checkbox"
							label={'Allow Enquiry'}
							id={'allow_email'}
							formik={formik}
							disabled
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Brand"
							id="brand_id"
							options={brandList}
							formik={formik}
							customOnChange={(e) => handleBrandChange(e.target.value)}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Model"
							id="model_id"
							options={modelList}
							formik={formik}
							customOnChange={(e) => handleModelChange(e.target.value)}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Sub Model"
							id="sub_model_id"
							options={subModelOption}
							formik={formik}
							customOnChange={(e) => handleSubModelChange(e)}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Category"
							id="category_id"
							options={carCategoryList?.filter(
								(car) => car?.type === 1 && car.status == 1
							)}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Price'}
							id={'price'}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2 mt-5">
						<FormField
							type="checkbox"
							label={'Featured'}
							id={'is_feature'}
							formik={formik}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Milage'}
							id={'mileage'}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="date"
							label={'Registration Date'}
							id={'registration_date'}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="date"
							label={'Original Registration Date'}
							id={'original_registration_date'}
							formik={formik}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'No Of Owner'}
							id={'no_of_owner'}
							formik={formik}
						/>
					</div>
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Depreciation'}
							id={'depreciation'}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Dereg Value'}
							id={'dereg_value'}
							formik={formik}
						/>
					</div>
					<div className="w-full xl:w-1/6">
						<button
							className="flex justify-center rounded border border-stroke py-2 px-6 mt-9 bg-primary font-medium text-gray "
							type="button"
							onClick={handleDeRegCalculation}
						>
							Generate
						</button>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField type="text" label={'COE'} id={'coe'} formik={formik} />
					</div>
					<div className="w-full xl:w-1/2">
						<FormField
							type="date"
							label={'COE Expiry Date'}
							id={'coe_expiry'}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="COE Type"
							id="cor_selection"
							options={coeTypeOptions}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField type="text" label={'OMV'} id={'omv'} formik={formik} />
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'PARF Rebate'}
							id={'arf'}
							formik={formik}
						/>
						<div className="w-full xl:w-1/2 mt-5">
							<FormField
								type="checkbox"
								label={'Forfeited'}
								id={'is_forFeited'}
								formik={formik}
							/>
						</div>
					</div>
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Transmission Type"
							id="transmission_type"
							options={transmissionTypeOptions}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Vehicle Type"
							id="vehicle_type_id"
							options={vehicleTypeList}
							formik={formik}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Engine Capacity (cc)'}
							id={'engine_capacity'}
							formik={formik}
						/>
					</div>
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Power (bhp)'}
							id={'power'}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Fuel Type"
							id="fuel_type_id"
							options={fuelTypeList}
							formik={formik}
							required
						/>
					</div>
				</div>
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-2/2">
						<FormField
							type="textarea"
							label={'Promotions (up to 500 characters)'}
							id={'promotions'}
							formik={formik}
						/>
					</div>
				</div>
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Chassis number (Optional)'}
							id={'chasis_number'}
							formik={formik}
						/>
					</div>
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Engine Number'}
							id={'engine_number'}
							formik={formik}
						/>
					</div>
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Remarks'}
							id={'remarks'}
							formik={formik}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Status"
							id="status"
							options={usedCarStatusOptions}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="date"
							label={'Ads Expiry Date'}
							id={'ads_expire_at'}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2"></div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-2/2">
						<FormField
							type="textarea"
							label={'Description (up to 500 characters)'}
							id={'desc'}
							formik={formik}
						/>
					</div>
				</div>

				<div>
					<MultipleImgUpdate imageSources={imageSources} formik={formik} id={'images'} multiple={true} />
				</div>

				{/* <ImageGrid
          imageSources={imageSources}
          handleDeleteImg={handleDeleteImg}
          usedCarGrid
        /> */}
			</div>
		</AdminForm>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.usedCar.loading,
		error: state.usedCar.error,
		success: state.usedCar.success,
		deleteimage: state.usedCar,
		carImgloading: state.usedCar.carImgloading,
		carImgerror: state.usedCar.carImgerror,
		carImgsuccess: state.usedCar.carImgsuccess,
		deregValue: state.usedCar.deregValue
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteImageUsedCar: (imgUrl) => dispatch(deleteImageUsedCar(imgUrl)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(UsedCarForm);
