import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../components/site/Layout/Base/Layout';
import HeroBox from '../../../components/site/CarsComponents/HeroBox';
import BrowseVehicleTypes from '../../../components/site/UsedCarComponents/BrowseVehicleTypes';
import FeaturedDeals from '../../../components/site/UsedCarComponents/FeaturedDeals';
import RecommendedByOurTeam from '../../../components/site/UsedCarComponents/RecommendedByOurTeam';
import SendMeGreatCarOffers from '../../../components/site/UsedCarComponents/SendMeGreatCarOffers';
import FAQ from '../../../components/site/UsedCarComponents/FAQ';
import FollowOnSocialMedia from '../../../components/site/FollowOnSocialMedia/FollowOnSocialMedia';
import usedCar from '../../../assets/images/web/used-car.webp';
import vehicleBanner from '../../../assets/images/web/vehicleBanner.png';
import offerday from '../../../assets/images/web/offerday.png';
import { useNavigate } from 'react-router-dom';

import {
  fetchVehicleTypePublicList,
  fetchCarModelList,
  fetchBrandPublicList,
} from '../../../store/actions';

const UsedCarHome = (props) => {
  const navigate = useNavigate();

  const {
    loading,
    error,
    success,
    vehicleTypePublicList,
    fetchVehicleTypePublicList,
    fetchCarModelList,
    fetchBrandPublicList,
    carModelList,
    brandPublicList,
  } = props;

  const searchHandler = (value) => {
    navigate(
      `/searched_used_cars?brand_id=${value.brand_id}&model_id=${value.model_id}&year_range=${value.year_range}&price_range=${value.price_range}&vehicle_id=${value.vehicle_id}`
    );
  };

  const fetchData = async () => {
    await Promise.all([
      fetchVehicleTypePublicList(),
      fetchBrandPublicList('all', true, true),
      fetchCarModelList('all', true, true),
    ]);
  };

  useEffect(() => {
    fetchData();
  }, [true]);

  return (
    <Layout>
      <div className="relative">
        <HeroBox
          title="USED CARS"
          img={usedCar}
          vehicleData={vehicleTypePublicList}
          brandList={brandPublicList}
          carModelList={carModelList}
          onSearch={searchHandler}
        />
      </div>
      <BrowseVehicleTypes vehicleTypeData={vehicleTypePublicList} />
      <div className="container mx-auto">
        <img src={vehicleBanner} alt="" className="my-5 w-full" />
        <img src={offerday} alt="" className=" w-full" />
      </div>
      <FeaturedDeals />
      <RecommendedByOurTeam
        title={'Recommended by our team'}
        dec={'Watch short videos or read in-depth tips from Car Inc team.'}
      />
      <SendMeGreatCarOffers />
      <FAQ
        title="Frequent Asked Question for Used Car"
        qus1="Where can I find used car deals?"
        qus2="How do I get the best deal on a used car?"
        qus3="How much per month is a used car?"
        qus4="Are used cars cheaper to insure?"
        qus5="Where can I get insurance for my used car?"
      />
      <FollowOnSocialMedia />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.usedCar.loading,
    error: state.usedCar.error,
    success: state.usedCar.success,
    vehicleTypePublicList: state.vehicleType.vehicleTypePublicList,
    brandPublicList: state.brand.brandPublicList,
    carModelList: state.carModel.carModelList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVehicleTypePublicList: () =>
      dispatch(fetchVehicleTypePublicList()),
    fetchBrandPublicList: (page) =>
      dispatch(fetchBrandPublicList(page)),
    fetchCarModelList: (page, isProcess, isPublic) =>
      dispatch(fetchCarModelList(page, isProcess, isPublic)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsedCarHome);
