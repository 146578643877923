// Dashboard.js
import React from 'react';
import heroImage from '../../../assets/images/web/image_1.webp';
import HeroBox from '../CarsComponents/HeroBox';
const HeroSection = ({ vehicleData, onSearch, brandList, carModelList }) => {
  return (
    <div className={`md:w-screen p-0  mb-16  md:bg-white container mx-auto`}>
      <HeroBox
        img={heroImage}
        vehicleData={vehicleData}
        brandList={brandList}
        carModelList={carModelList}
        onSearch={onSearch}
        title={'USED CARS'}
      />
    </div>
  );
};

export default HeroSection;
