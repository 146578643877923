// Footer.js
import React, { useEffect, useState } from 'react';
import { IoIosArrowDropup } from 'react-icons/io';
import { MdOutlineArrowOutward } from 'react-icons/md';

import {
	RiFacebookBoxFill,
	RiInstagramLine,
	RiTiktokFill,
	RiYoutubeFill,
} from 'react-icons/ri';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/web/Car_Inc_Logo.webp';
import { fetchPublicSettingSingle } from '../../../store/actions';

const Footer = ({ fetchPublicSettingSingle, publicSetting }) => {
	const [isVisible, setIsVisible] = useState(true);
	const navigate = useNavigate();

	const handleScroll = () => {
		const scrollY = window.scrollY;
		setIsVisible(scrollY);
	};
	// useEffect(() => {
	// 	fetchPublicSettingSingle();
	// }, []);
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const footerLinkNavigate = (routeLink) => {
		navigate(routeLink);
	};
	return (
		<footer className=" p-4 my-5 ">
			<div className="flex-col justify-center text-center cursor-pointer items-center my-3 mb-12">
				{isVisible && (
					<button onClick={scrollToTop}>
						<IoIosArrowDropup size={40} className="ml-3.5" />
						<p> Back to top</p>
					</button>
				)}
			</div>
			<hr className="my-10 border border-[#E8E7E6] " />
			<div className="container mx-auto my-12  ">
				<div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-4 ">
					<div className="mx-auto md:ml-0 mt-10">
						<div>
							{/* <a href="/">
                <WebLogo className="w-[132px] h-[34.991px] -mt-14 sm:mx-auto my-8 cursor-pointer" />
              </a> */}
							<Link to={'/'}>
								<div className={` w-full h-full md:mr-12 mx-auto`}>
									{/* <img
                src={logo}
                className={`cursor-pointer lg:order-1 md:order-2 w-[132px] h-[34.991px] md:mr-12 mx-auto `}
              /> */}
									<img
										src={publicSetting?.image ? publicSetting?.image : ''}
										alt="CAR INC"
										className={`cursor-pointer lg:order-1 md:order-2  rounded-md
              `}
									/>
								</div>
							</Link>
						</div>
						<ul className="space-y-2 my-12">
							<li
								onClick={() => footerLinkNavigate('/new_car_home')}
								className="cursor-pointer"
							>
								New Car for Sales
							</li>
							<li
								onClick={() => footerLinkNavigate('/used_car_home')}
								className="cursor-pointer"
							>
								Used Car for Sales
							</li>
							<li onClick={scrollToTop} className="cursor-pointer">
								Cars for Rent
							</li>
							<li
								onClick={() => footerLinkNavigate('/sell-your-car')}
								className="cursor-pointer"
							>
								Post an Ad
							</li>
							<li
								onClick={() => footerLinkNavigate('/news')}
								className="cursor-pointer"
							>
								Car News
							</li>
							<li onClick={scrollToTop} className="cursor-pointer">
								About Us
							</li>
							{/* <li className="cursor-pointer">Career</li> */}
							<li onClick={scrollToTop} className="cursor-pointer">
								Contact us
							</li>
							<li onClick={scrollToTop} className="my-8 cursor-pointer">
								Manage cookies
							</li>
						</ul>
					</div>
					<div className="px-3 mt-16">
						<div className="flex items-center px-3 py-5  border-[#E8E7E6] border-b border-t">
							<MdOutlineArrowOutward
								className="font-normal mx-2"
								size={30}
								fontWeight={100}
							/>
							<span onClick={scrollToTop} className="cursor-pointer">
								Car Loan
							</span>
						</div>
						<div className="flex items-center px-3 py-5  ">
							<MdOutlineArrowOutward
								className="font-normal mx-2"
								size={30}
								fontWeight={100}
							/>
							<span onClick={scrollToTop} className="cursor-pointer">
								Car Insurance
							</span>
						</div>
						<div className="flex items-center px-3 py-5 border-b border-t  border-[#E8E7E6]">
							<MdOutlineArrowOutward
								className="font-normal mx-2 g"
								size={30}
								fontWeight={100}
							/>
							<span onClick={scrollToTop} className="cursor-pointer">
								COE Prices
							</span>
						</div>
					</div>

					<div className="px-3 mx-auto md:ml-0 mt-8 text-center md:text-start lg:text-start">
						<p>Help us improve</p>
						<div>
							<button
								onClick={scrollToTop}
								className="text-[#0534FF] px-10 py-2.5 my-2.5 font-semibold border-[#0534FF] border "
							>
								Send feedback
							</button>
						</div>
						<div className="w-64 flex justify-center items-center my-3 gap-8 mx-auto md:mx-0 lg:mx-0">
							<a href="https://www.facebook.com/" target="blank">
								<RiFacebookBoxFill size={35} className=" opacity-75" />
							</a>
							<a href="https://www.youtube.com/" target="blank">
								<RiYoutubeFill size={35} className=" opacity-75" />
							</a>
							<a href="https://www.instagram.com/" target="blank">
								<RiInstagramLine size={35} className=" opacity-75" />
							</a>
							<a href="https://www.tiktok.com/" target="blank">
								<RiTiktokFill size={35} className=" opacity-75" />
							</a>
						</div>
						<div className="text-[#666666]">
							<p className="mt-12 mb-10">
								Copyright © Car Inc Private Limited 2023.
							</p>
							<p className="my-6">Registered office and headquarters</p>
							Oxley Bizhub 2 <br />
							62 Ubi Road 1, #08-01 <br />
							Singapore, 408734 <br />
							UEN Number: 03909628 <br />
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

const mapStateToProps = (state) => {
	return {
		isAuth: state.auth.isAuthenticated,
		redirectTo: state.auth.redirectTo,
		publicSetting: state.setting.publicSetting,
		userData: state.auth.userData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchPublicSettingSingle: () => dispatch(fetchPublicSettingSingle()),
	};
};

export default connect(mapStateToProps, null)(Footer);
